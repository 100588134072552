import React, { useState } from 'react';
import logo from './assets/telnwa-logo.svg'; // Assuming the SVG logo is in the assets folder
import background from './assets/real-estate-background.jpg'; // Background image

function App() {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="min-h-screen flex flex-col bg-white text-gray-900 relative">
      {/* Navigation */}
      <nav className="w-full bg-white p-4 flex justify-between items-center shadow-lg">
        <img src={logo} alt="Telnwa Logo" className="h-12" />
        <button 
          onClick={() => setShowForm(true)} 
          className="px-6 py-2 bg-purple-700 text-white font-semibold rounded-lg shadow hover:bg-purple-800 transition"
        >
          Get in Touch
        </button>
      </nav>

      {/* Hero Section */}
      <header 
        className="relative w-full h-screen flex flex-col justify-center items-center text-center px-4 bg-cover bg-center" 
        style={{ backgroundImage: `url(${background})` }} // Use the photorealistic background
      >
        {/* Increase opacity for better text contrast */}
        <div className="absolute inset-0 bg-purple-800 bg-opacity-70"></div> {/* Darker Overlay */}

        <div className="relative z-10 p-8 max-w-xl">
          {/* Add a text shadow for readability */}
          <h1 className="text-6xl font-extrabold text-white mb-6 drop-shadow-2xl">Welcome to Telnwa Investment</h1>
          <p className="text-xl text-white mb-8 leading-relaxed drop-shadow-2xl">
            Providing Premium Long-Term Rentals and Serviced Accommodations in United Kingdom and Sweden's Top Cities.
            Our mission is to build scalable, sustainable, and tech-driven real estate solutions.
          </p>
          <button 
            onClick={() => setShowForm(true)} 
            className="px-8 py-4 bg-white text-purple-800 text-lg font-bold rounded-lg shadow-lg transition transform hover:scale-105"
          >
            Learn More
          </button>
        </div>
      </header>

      {/* Contact Form Modal */}
      {showForm && (
        <div 
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-md z-50" 
          onClick={() => setShowForm(false)} // Close form when clicking outside
        >
          <div 
            className="bg-white p-10 shadow-xl rounded-lg max-w-md w-full relative"
            onClick={(e) => e.stopPropagation()} // Prevent click propagation to the backdrop
          >
            <button 
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
              onClick={() => setShowForm(false)}
            >
              &times; {/* Close button */}
            </button>
            <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">Contact Us</h2>
            <form className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name:</label>
                <input 
                  type="text" 
                  className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  placeholder="Enter your name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email:</label>
                <input 
                  type="email" 
                  className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  placeholder="Enter your email"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Message:</label>
                <textarea 
                  className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  placeholder="Your message"
                ></textarea>
              </div>
              <button 
                type="submit" 
                className="w-full py-3 bg-purple-700 hover:bg-purple-800 text-white font-semibold rounded-md shadow-md text-lg transition transform hover:scale-105"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
